import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';

export const useParseRules = tournamentRules => {
  const quillRef = useRef(null);
  const [rules, setRules] = useState('');

  useEffect(() => {
    if (quillRef?.current) {
      setRules(quillRef?.current.unprivilegedEditor.getText(0, 667));
    }
  }, []);

  return (
    <>
      <ReactQuill ref={quillRef} value={tournamentRules} style={{ display: 'none' }} />
      {rules}
    </>
  );
};