import React, {memo, useEffect} from 'react';
import classNames from 'classnames';
import {
  ACTION_ENDED,
  ACTION_LIVE, ACTION_UPCOMING,
  labelsList, WIDGET_STATUS_ENDED, WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING,
} from 'enums/tournament';
import styles from '../styles.module.less';
import {msgToParent} from '../../../services/processPostMessage/ProcessPostMessage';
import {useNavigate} from 'react-router-dom';

const Filter = ({translations, tabId}) => {
  const navigate = useNavigate();

  const handleFilter = (state, label) => {
    navigate(`/${label}`);
    msgToParent({type: 'currentURL', value: encodeURIComponent(`/${label}`)});
  };

  const actionsList = [
    {
      state: null,
      name: translations?.allTournaments,
      label: '',
    },
    {
      state: WIDGET_STATUS_LIVE,
      name: translations?.liveTournaments,
      label: ACTION_LIVE,
    },
    {
      state: WIDGET_STATUS_UPCOMING,
      name: translations?.upcomingTournaments,
      label: ACTION_UPCOMING,
    },
    {
      state: WIDGET_STATUS_ENDED,
      name: translations?.endedTournaments,
      label: ACTION_ENDED,
    },
  ];


  return (
    <div className={styles.tournamentListTabs}>
      {
        actionsList.map(item => (
          <button
            key={item.state}
            type='button'
            onClick={() => handleFilter(item.state, item.label)}
            className={classNames({[styles.tabActive]: (labelsList[tabId] ?? null) === item.state})}
          >
            {item.name}
          </button>
        ))
      }
    </div>
  );
};

export default memo(Filter);