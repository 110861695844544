export const FirstPlaceIcon = () => (
  <svg width='40' height='28' viewBox='0 0 40 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M36.672 3.99881C34.8308 3.99881 33.3382 5.49145 33.3382 7.33266C33.3389 7.68216 33.3951 8.02932 33.5048 8.36117L30.8011 10.0798C29.0209 11.214 26.6738 10.8381 25.3369 9.20465L22.6865 5.96253C23.1083 5.39472 23.3363 4.70626 23.3366 3.99889C23.3366 2.15768 21.844 0.665039 20.0028 0.665039C18.1616 0.665039 16.6689 2.15768 16.6689 3.99889C16.6692 4.70626 16.8972 5.39472 17.319 5.96253L14.6686 9.20465C13.332 10.8372 10.986 11.213 9.20619 10.0798L6.50095 8.36117C6.61073 8.02932 6.66699 7.68216 6.66762 7.33266C6.66793 5.49145 5.1756 3.99857 3.33432 3.99826C1.49319 3.99795 0.000312601 5.49036 5.54633e-08 7.33156C-0.00031249 9.05994 1.32035 10.5019 3.04209 10.6531L5.01237 22.4715C5.07918 22.8734 5.42673 23.1681 5.83414 23.1683H34.1716C34.579 23.1681 34.9266 22.8734 34.9934 22.4715L36.9637 10.6531C38.7976 10.4887 40.1509 8.86866 39.9864 7.0348C39.8325 5.31822 38.3955 4.00201 36.672 3.99881Z'
      fill='#FFC107' />
    <path
      d='M20.0027 26.5025C27.8279 26.5025 34.1714 24.6367 34.1714 22.3352C34.1714 20.0337 27.8279 18.168 20.0027 18.168C12.1775 18.168 5.83398 20.0337 5.83398 22.3352C5.83398 24.6367 12.1775 26.5025 20.0027 26.5025Z'
      fill='#FFA000' />
    <path
      d='M20.0022 27.3354C12.5461 27.3354 5 25.6186 5 22.3347C5 19.0509 12.5461 17.334 20.0022 17.334C27.4583 17.334 35.0044 19.0509 35.0044 22.3347C35.0044 25.6186 27.4583 27.3354 20.0022 27.3354ZM20.0022 19.0009C11.2476 19.0009 6.66688 21.1678 6.66688 22.3347C6.66688 23.5016 11.2476 25.6686 20.0022 25.6686C28.7568 25.6686 33.3375 23.5016 33.3375 22.3347C33.3375 21.1678 28.7568 19.0009 20.0022 19.0009Z'
      fill='#FFD54F' />
  </svg>
);
export const CanceledIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    fill='none'
    viewBox='0 0 14 14'
  >
    <path fill='#FEFEFE'
          d='M7 13.667c3.673 0 6.667-2.993 6.667-6.667C13.667 3.327 10.673.334 7 .334 3.327.334.333 3.327.333 7c0 3.674 2.994 6.667 6.667 6.667zm.5-4c0 .273-.227.5-.5.5a.504.504 0 01-.5-.5V6.334c0-.274.227-.5.5-.5s.5.226.5.5v3.333zM6.387 4.08a.688.688 0 01.14-.22.77.77 0 01.22-.14A.664.664 0 017 3.667c.087 0 .173.02.253.053a.77.77 0 01.22.14c.06.067.107.134.14.22.034.08.054.167.054.254 0 .086-.02.173-.054.253a.77.77 0 01-.14.22.77.77 0 01-.22.14.667.667 0 01-.506 0 .77.77 0 01-.22-.14.77.77 0 01-.14-.22.664.664 0 01-.054-.253c0-.087.02-.174.054-.254z'
    />
  </svg>
);
export const SecondPlaceIcon = () => (
  <svg width='40' height='28' viewBox='0 0 40 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M36.672 3.99881C34.8308 3.99881 33.3382 5.49145 33.3382 7.33266C33.3389 7.68216 33.3951 8.02932 33.5048 8.36117L30.8011 10.0798C29.0209 11.214 26.6738 10.8381 25.3369 9.20465L22.6865 5.96253C23.1083 5.39472 23.3363 4.70626 23.3366 3.99889C23.3366 2.15768 21.844 0.665039 20.0028 0.665039C18.1616 0.665039 16.6689 2.15768 16.6689 3.99889C16.6692 4.70626 16.8972 5.39472 17.319 5.96253L14.6686 9.20465C13.332 10.8372 10.986 11.213 9.20619 10.0798L6.50095 8.36117C6.61073 8.02932 6.66699 7.68216 6.66762 7.33266C6.66793 5.49145 5.1756 3.99857 3.33432 3.99826C1.49319 3.99795 0.000312601 5.49036 5.54633e-08 7.33156C-0.00031249 9.05994 1.32035 10.5019 3.04209 10.6531L5.01237 22.4715C5.07918 22.8734 5.42673 23.1681 5.83414 23.1683H34.1716C34.579 23.1681 34.9266 22.8734 34.9934 22.4715L36.9637 10.6531C38.7976 10.4887 40.1509 8.86866 39.9864 7.0348C39.8325 5.31822 38.3955 4.00201 36.672 3.99881Z'
      fill='#D1D8DC' />
    <path
      d='M20.0027 26.5025C27.8279 26.5025 34.1714 24.6367 34.1714 22.3352C34.1714 20.0337 27.8279 18.168 20.0027 18.168C12.1775 18.168 5.83398 20.0337 5.83398 22.3352C5.83398 24.6367 12.1775 26.5025 20.0027 26.5025Z'
      fill='#B5BCC0' />
    <path
      d='M20.0022 27.3354C12.5461 27.3354 5 25.6186 5 22.3347C5 19.0509 12.5461 17.334 20.0022 17.334C27.4583 17.334 35.0044 19.0509 35.0044 22.3347C35.0044 25.6186 27.4583 27.3354 20.0022 27.3354ZM20.0022 19.0009C11.2476 19.0009 6.66688 21.1678 6.66688 22.3347C6.66688 23.5016 11.2476 25.6686 20.0022 25.6686C28.7568 25.6686 33.3375 23.5016 33.3375 22.3347C33.3375 21.1678 28.7568 19.0009 20.0022 19.0009Z'
      fill='#95A2A9' />
  </svg>
);

export const thirdPlaceIcon = () => (
  <svg width='40' height='28' viewBox='0 0 40 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M36.672 3.99881C34.8308 3.99881 33.3382 5.49145 33.3382 7.33266C33.3389 7.68216 33.3951 8.02932 33.5048 8.36117L30.8011 10.0798C29.0209 11.214 26.6738 10.8381 25.3369 9.20465L22.6865 5.96253C23.1083 5.39472 23.3363 4.70626 23.3366 3.99889C23.3366 2.15768 21.844 0.665039 20.0028 0.665039C18.1616 0.665039 16.6689 2.15768 16.6689 3.99889C16.6692 4.70626 16.8972 5.39472 17.319 5.96253L14.6686 9.20465C13.332 10.8372 10.986 11.213 9.20619 10.0798L6.50095 8.36117C6.61073 8.02932 6.66699 7.68216 6.66762 7.33266C6.66793 5.49145 5.1756 3.99857 3.33432 3.99826C1.49319 3.99795 0.000312601 5.49036 5.54633e-08 7.33156C-0.00031249 9.05994 1.32035 10.5019 3.04209 10.6531L5.01237 22.4715C5.07918 22.8734 5.42673 23.1681 5.83414 23.1683H34.1716C34.579 23.1681 34.9266 22.8734 34.9934 22.4715L36.9637 10.6531C38.7976 10.4887 40.1509 8.86866 39.9864 7.0348C39.8325 5.31822 38.3955 4.00201 36.672 3.99881Z'
      fill='#F99B43' />
    <path
      d='M20.0027 26.5025C27.8279 26.5025 34.1714 24.6367 34.1714 22.3352C34.1714 20.0337 27.8279 18.168 20.0027 18.168C12.1775 18.168 5.83398 20.0337 5.83398 22.3352C5.83398 24.6367 12.1775 26.5025 20.0027 26.5025Z'
      fill='#DB8A4C' />
    <path
      d='M20.0022 27.3354C12.5461 27.3354 5 25.6186 5 22.3347C5 19.0509 12.5461 17.334 20.0022 17.334C27.4583 17.334 35.0044 19.0509 35.0044 22.3347C35.0044 25.6186 27.4583 27.3354 20.0022 27.3354ZM20.0022 19.0009C11.2476 19.0009 6.66688 21.1678 6.66688 22.3347C6.66688 23.5016 11.2476 25.6686 20.0022 25.6686C28.7568 25.6686 33.3375 23.5016 33.3375 22.3347C33.3375 21.1678 28.7568 19.0009 20.0022 19.0009Z'
      fill='#FFC38E' />
  </svg>
);

export const fourthPlaceIcon = () => (
  <svg width='40' height='28' viewBox='0 0 40 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M36.672 3.99881C34.8308 3.99881 33.3382 5.49145 33.3382 7.33266C33.3389 7.68216 33.3951 8.02932 33.5048 8.36117L30.8011 10.0798C29.0209 11.214 26.6738 10.8381 25.3369 9.20465L22.6865 5.96253C23.1083 5.39472 23.3363 4.70626 23.3366 3.99889C23.3366 2.15768 21.844 0.665039 20.0028 0.665039C18.1616 0.665039 16.6689 2.15768 16.6689 3.99889C16.6692 4.70626 16.8972 5.39472 17.319 5.96253L14.6686 9.20465C13.332 10.8372 10.986 11.213 9.20619 10.0798L6.50095 8.36117C6.61073 8.02932 6.66699 7.68216 6.66762 7.33266C6.66793 5.49145 5.1756 3.99857 3.33432 3.99826C1.49319 3.99795 0.000312601 5.49036 5.54633e-08 7.33156C-0.00031249 9.05994 1.32035 10.5019 3.04209 10.6531L5.01237 22.4715C5.07918 22.8734 5.42673 23.1681 5.83414 23.1683H34.1716C34.579 23.1681 34.9266 22.8734 34.9934 22.4715L36.9637 10.6531C38.7976 10.4887 40.1509 8.86866 39.9864 7.0348C39.8325 5.31822 38.3955 4.00201 36.672 3.99881Z'
      fill='#58C0F9' />
    <path
      d='M20.0027 26.5025C27.8279 26.5025 34.1714 24.6367 34.1714 22.3352C34.1714 20.0337 27.8279 18.168 20.0027 18.168C12.1775 18.168 5.83398 20.0337 5.83398 22.3352C5.83398 24.6367 12.1775 26.5025 20.0027 26.5025Z'
      fill='#4294D6' />
    <path
      d='M20.0022 27.3354C12.5461 27.3354 5 25.6186 5 22.3347C5 19.0509 12.5461 17.334 20.0022 17.334C27.4583 17.334 35.0044 19.0509 35.0044 22.3347C35.0044 25.6186 27.4583 27.3354 20.0022 27.3354ZM20.0022 19.0009C11.2476 19.0009 6.66688 21.1678 6.66688 22.3347C6.66688 23.5016 11.2476 25.6686 20.0022 25.6686C28.7568 25.6686 33.3375 23.5016 33.3375 22.3347C33.3375 21.1678 28.7568 19.0009 20.0022 19.0009Z'
      fill='#9AD8FB' />
  </svg>
);


export const CrossIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_11776_67736)'>
      <path
        d='M16.5408 4.22169L15.7783 3.45921C15.6978 3.37901 15.5887 3.33398 15.475 3.33398C15.3613 3.33398 15.2522 3.37901 15.1716 3.45921L10.3025 8.32836C10.2627 8.36828 10.2154 8.39994 10.1634 8.42154C10.1113 8.44315 10.0555 8.45427 9.99915 8.45427C9.94279 8.45427 9.88699 8.44315 9.83494 8.42154C9.78288 8.39994 9.73562 8.36828 9.69583 8.32836L4.82665 3.45921C4.74606 3.37901 4.63702 3.33398 4.52333 3.33398C4.40964 3.33398 4.30056 3.37901 4.21998 3.45921L3.4575 4.22169C3.3773 4.30227 3.33228 4.41135 3.33228 4.52503C3.33228 4.63872 3.3773 4.74777 3.4575 4.82835L8.32666 9.69754C8.36657 9.73733 8.39823 9.78459 8.41984 9.83664C8.44144 9.8887 8.45257 9.9445 8.45257 10.0009C8.45257 10.0572 8.44144 10.113 8.41984 10.1651C8.39823 10.2171 8.36657 10.2644 8.32666 10.3042L3.4575 15.1734C3.3773 15.2539 3.33228 15.363 3.33228 15.4767C3.33228 15.5904 3.3773 15.6994 3.4575 15.78L4.21998 16.5425C4.25977 16.5824 4.30706 16.6141 4.35912 16.6357C4.41117 16.6573 4.46697 16.6684 4.52333 16.6684C4.57968 16.6684 4.63548 16.6573 4.68754 16.6357C4.73959 16.6141 4.78685 16.5824 4.82665 16.5425L9.69583 11.6734C9.77641 11.5932 9.88546 11.5481 9.99915 11.5481C10.1128 11.5481 10.2219 11.5932 10.3025 11.6734L15.1716 16.5425C15.2114 16.5824 15.2587 16.6141 15.3108 16.6357C15.3628 16.6573 15.4186 16.6684 15.475 16.6684C15.5314 16.6684 15.5872 16.6573 15.6392 16.6357C15.6913 16.6141 15.7385 16.5824 15.7783 16.5425L16.5408 15.78C16.5807 15.7402 16.6124 15.693 16.634 15.6409C16.6556 15.5889 16.6667 15.5331 16.6667 15.4767C16.6667 15.4203 16.6556 15.3645 16.634 15.3125C16.6124 15.2604 16.5807 15.2131 16.5408 15.1734L11.6717 10.3042C11.5915 10.2236 11.5464 10.1145 11.5464 10.0009C11.5464 9.88717 11.5915 9.77812 11.6717 9.69754L16.5408 4.82835C16.5807 4.78856 16.6124 4.7413 16.634 4.68924C16.6556 4.63719 16.6667 4.58139 16.6667 4.52503C16.6667 4.46868 16.6556 4.41288 16.634 4.36082C16.6124 4.30877 16.5807 4.26148 16.5408 4.22169Z'
        fill='var(--placeholder-text-color)' />
    </g>
    <defs>
      <clipPath id='clip0_11776_67736'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const CrossSecondIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.94933 8.00033L13.1359 3.81374C13.3056 3.64402 13.3719 3.3967 13.3098 3.16487C13.2477 2.93311 13.0666 2.752 12.8348 2.68987C12.603 2.62782 12.3556 2.69404 12.1859 2.86375L7.99935 7.05034L3.81276 2.86375C3.64305 2.69404 3.39572 2.62781 3.1639 2.68987C2.93213 2.752 2.75103 2.93311 2.6889 3.16487C2.62684 3.39672 2.69306 3.64403 2.86278 3.81374L7.04936 8.00033L2.86278 12.1869C2.69306 12.3566 2.62684 12.604 2.6889 12.8358C2.75102 13.0675 2.93213 13.2486 3.1639 13.3108C3.39574 13.3728 3.64305 13.3066 3.81276 13.1369L7.99935 8.95031L12.1859 13.1369C12.3557 13.3066 12.603 13.3728 12.8348 13.3108C13.0666 13.2487 13.2477 13.0675 13.3098 12.8358C13.3719 12.6039 13.3056 12.3566 13.1359 12.1869L8.94933 8.00033Z'
      fill='#FEFEFE' />
  </svg>
);

export const PrivateIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.94 7.41289V4.48622C13.94 3.93956 13.5267 3.31956 13.0133 3.11289L9.3 1.59289C8.46666 1.25289 7.52666 1.25289 6.69333 1.59289L2.98 3.11289C2.47333 3.31956 2.06 3.93956 2.06 4.48622V7.41289C2.06 10.6729 4.42666 13.7262 7.66 14.6196C7.88 14.6796 8.12 14.6796 8.34 14.6196C11.5733 13.7262 13.94 10.6729 13.94 7.41289ZM8.5 8.57956V10.3329C8.5 10.6062 8.27333 10.8329 8 10.8329C7.72666 10.8329 7.5 10.6062 7.5 10.3329V8.57956C6.82666 8.36622 6.33333 7.73956 6.33333 6.99956C6.33333 6.07956 7.08 5.33289 8 5.33289C8.92 5.33289 9.66666 6.07956 9.66666 6.99956C9.66666 7.74622 9.17333 8.36622 8.5 8.57956Z'
      fill='#FEFEFE' />
  </svg>
);

export const SearchIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.66732 14.4997C3.90065 14.4997 0.833984 11.433 0.833984 7.66634C0.833984 3.89967 3.90065 0.833008 7.66732 0.833008C11.434 0.833008 14.5007 3.89967 14.5007 7.66634C14.5007 11.433 11.434 14.4997 7.66732 14.4997ZM7.66732 1.83301C4.44732 1.83301 1.83398 4.45301 1.83398 7.66634C1.83398 10.8797 4.44732 13.4997 7.66732 13.4997C10.8873 13.4997 13.5007 10.8797 13.5007 7.66634C13.5007 4.45301 10.8873 1.83301 7.66732 1.83301Z'
      fill='var(--placeholder-text-color)' />
    <path
      d='M14.6676 15.1666C14.5409 15.1666 14.4143 15.12 14.3143 15.02L12.9809 13.6866C12.7876 13.4933 12.7876 13.1733 12.9809 12.98C13.1743 12.7866 13.4943 12.7866 13.6876 12.98L15.0209 14.3133C15.2143 14.5066 15.2143 14.8266 15.0209 15.02C14.9209 15.12 14.7943 15.1666 14.6676 15.1666Z'
      fill='var(--placeholder-text-color)' />
  </svg>
);

export const ClearCircleIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.00065 1.33301C4.32732 1.33301 1.33398 4.32634 1.33398 7.99967C1.33398 11.673 4.32732 14.6663 8.00065 14.6663C11.674 14.6663 14.6673 11.673 14.6673 7.99967C14.6673 4.32634 11.674 1.33301 8.00065 1.33301ZM10.2407 9.53301C10.434 9.72634 10.434 10.0463 10.2407 10.2397C10.1407 10.3397 10.014 10.3863 9.88732 10.3863C9.76065 10.3863 9.63398 10.3397 9.53399 10.2397L8.00065 8.70634L6.46732 10.2397C6.36732 10.3397 6.24065 10.3863 6.11398 10.3863C5.98732 10.3863 5.86065 10.3397 5.76065 10.2397C5.56732 10.0463 5.56732 9.72634 5.76065 9.53301L7.29398 7.99967L5.76065 6.46634C5.56732 6.27301 5.56732 5.95301 5.76065 5.75968C5.95398 5.56634 6.27398 5.56634 6.46732 5.75968L8.00065 7.29301L9.53399 5.75968C9.72732 5.56634 10.0473 5.56634 10.2407 5.75968C10.434 5.95301 10.434 6.27301 10.2407 6.46634L8.70732 7.99967L10.2407 9.53301Z'
      fill='var(--placeholder-text-color)' />
  </svg>
);

export const PeriodicIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.25768 14.3169C3.09935 14.3169 2.94102 14.2585 2.81602 14.1335C1.67435 12.9835 1.04102 11.4669 1.04102 9.85852C1.04102 6.51685 3.74935 3.80018 7.08268 3.80018L12.141 3.81685L11.2327 2.95018C10.9827 2.70852 10.9743 2.31685 11.216 2.06685C11.4577 1.81685 11.8493 1.80852 12.0993 2.05018L14.1327 4.00018C14.316 4.17518 14.3743 4.45018 14.2827 4.68352C14.191 4.91685 13.9577 5.07518 13.6993 5.07518L7.08268 5.05852C4.44102 5.05852 2.29102 7.21685 2.29102 9.86685C2.29102 11.1419 2.79102 12.3502 3.69935 13.2585C3.94102 13.5002 3.94102 13.9002 3.69935 14.1419C3.57435 14.2585 3.41602 14.3169 3.25768 14.3169Z'
      fill='var(--text-color)' />
    <path
      d='M8.33325 18.1247C8.17492 18.1247 8.02492 18.0663 7.89992 17.9497L5.86658 15.9997C5.68325 15.8247 5.62492 15.5497 5.71658 15.3163C5.80825 15.083 6.04158 14.9247 6.29992 14.9247L12.9249 14.9413C15.5666 14.9413 17.7166 12.783 17.7166 10.133C17.7166 8.85801 17.2166 7.64967 16.3083 6.74134C16.0666 6.49967 16.0666 6.09967 16.3083 5.85801C16.5499 5.61634 16.9499 5.61634 17.1916 5.85801C18.3333 7.00801 18.9666 8.52467 18.9666 10.133C18.9666 13.4747 16.2583 16.1913 12.9249 16.1913L7.86658 16.1747L8.77492 17.0413C9.02492 17.283 9.03325 17.6747 8.79158 17.9247C8.65825 18.058 8.49992 18.1247 8.33325 18.1247Z'
      fill='var(--text-color)' />
    <path
      d='M7.50065 12.9163H12.5007C14.1007 12.9163 15.4173 11.608 15.4173 9.99967C15.4173 8.39967 14.109 7.08301 12.5007 7.08301H7.50065C5.90065 7.08301 4.58398 8.39134 4.58398 9.99967C4.58398 11.608 5.89232 12.9163 7.50065 12.9163Z'
      fill='var(--text-color)' />
  </svg>
);

export const PlayModeIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='14' viewBox='0 0 12 14' fill='none'>
    <path
      d='M9.81537 10.3509L7.55572 11.7835L5.29606 13.2161C2.38323 15.0553 0 13.5452 0 9.86694V7.00173V4.13653C0 0.438862 2.38323 -1.05182 5.29606 0.787332L7.55572 2.21993L9.81537 3.65254C12.7282 5.49169 12.7282 8.51177 9.81537 10.3509Z'
      fill='#28282C' />
  </svg>
);


export const CancelledIconSingle = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.99996 1.66602C5.40829 1.66602 1.66663 5.40768 1.66663 9.99935C1.66663 14.591 5.40829 18.3327 9.99996 18.3327C14.5916 18.3327 18.3333 14.591 18.3333 9.99935C18.3333 5.40768 14.5916 1.66602 9.99996 1.66602ZM12.8 11.916C13.0416 12.1577 13.0416 12.5577 12.8 12.7993C12.675 12.9243 12.5166 12.9827 12.3583 12.9827C12.2 12.9827 12.0416 12.9243 11.9166 12.7993L9.99996 10.8827L8.08329 12.7993C7.95829 12.9243 7.79996 12.9827 7.64163 12.9827C7.48329 12.9827 7.32496 12.9243 7.19996 12.7993C6.95829 12.5577 6.95829 12.1577 7.19996 11.916L9.11663 9.99935L7.19996 8.08268C6.95829 7.84102 6.95829 7.44102 7.19996 7.19935C7.44163 6.95768 7.84163 6.95768 8.08329 7.19935L9.99996 9.11602L11.9166 7.19935C12.1583 6.95768 12.5583 6.95768 12.8 7.19935C13.0416 7.44102 13.0416 7.84102 12.8 8.08268L10.8833 9.99935L12.8 11.916Z'
      fill='#E35656' />
  </svg>
);


export const ArrowLeftIcon = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='40' height='40' rx='20' fill='white' fillOpacity='0.5' />
    <path
      d='M17.9774 12.9401L12.0933 18.9421C11.5245 19.5223 11.5245 20.4827 12.0933 21.0629L17.9774 27.0649C18.5462 27.645 19.4876 27.645 20.0564 27.0649C20.6252 26.4847 20.6252 25.5243 20.0564 24.9441L16.6829 21.503L26.8624 21.503C27.6665 21.503 28.3334 20.8228 28.3334 20.0025C28.3334 19.1822 27.6665 18.502 26.8624 18.502L16.6829 18.502L20.0564 15.0609C20.6252 14.4807 20.6252 13.5203 20.0564 12.9401C19.7622 12.64 19.3896 12.5 19.0169 12.5C18.6443 12.5 18.2716 12.64 17.9774 12.9401Z'
      fill='#FEFEFE' />
  </svg>
);