import React, {useEffect, useState} from 'react';
import useTournamentAPI from 'apis/useTournamentAPI';
import Wrapper from './components/Wrapper';
import {msgToParent} from '../../services/processPostMessage/ProcessPostMessage';
import {moveStatusEndToEnd} from '../../utils/helper';

export default () => {
  const {getTournaments} = useTournamentAPI();
  const [loading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [playerLoggedIn, setPlayerLoggedIn] = useState(false);

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const tournamentsData = await getTournaments();
      if (tournamentsData?.hasError === false) {
        const sortedArr = moveStatusEndToEnd(tournamentsData.data?.tournaments);

        setTournaments(sortedArr);
        setPlayerLoggedIn(tournamentsData.data.playerLoggedIn);
      }
      setLoading(false);
    };

    initData();
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'unset';
    msgToParent({type: 'currentURL', value: encodeURIComponent(`${location.pathname}`)});
  }, []);

  return (
    <Wrapper
      tournaments={tournaments}
      playerLoggedIn={playerLoggedIn}
      loading={loading}
    />
  );
};
