import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { formatNumber } from 'utils/helper';
import Status from 'components/Status';
import { AuthContext } from 'context/AuthContext';
import useGetBreakpoints from 'lib/hooks/useGetBreakpoints/useGetBreakpoints';
import JoinButtonAndDateTimeCounterSingle from './JoinButtonAndDateTimeCounterSingle';
import { Private } from './Private';
import Periodic from '../Periodic';
import styles from '../styles.module.less';

export default (props) => {
  const { tournament, isLoading } = props;
  const [status, setStatus] = useState(tournament.status);
  const [state] = useContext(AuthContext);
  const { checkBreakpoint } = useGetBreakpoints();
  const isTabletOrMobile = checkBreakpoint(['sm', 'md']);
  const isDesktop = !isTabletOrMobile;

  useEffect(() => {
    setStatus(tournament.status);
  }, [tournament.status]);

  return (
    <div className={styles.tournamentSingleHeader}>
      <div className={styles.tournamentSingleHeaderLeftSide}>
        <div className={styles.tournamentSingleTitle}>
          <span>{tournament?.name}</span>

          {
            isDesktop && <>
              <Status
                status={status}
                className={classNames(styles.tournamentSingleStatus, 'desktop')}
              />
              <Periodic
                type={tournament.type}
                state={state}
              />
              <Private
                accessType={tournament.accessType}
                state={state}
              />
            </>
          }
        </div>

        <div className={styles.tournamentSinglePrize}>
          <div className={styles.tournamentSinglePrizeType}>{state.translations?.prizePool}</div>
          <div className={styles.tournamentSinglePrizeAmount}>
            {formatNumber(tournament?.prizePool, tournament?.currency)}
          </div>
        </div>

        {
          isTabletOrMobile && (
            <div className={styles.tournamentSinglePrizeStatus}>
              <Status
                status={status}
                className={styles.tournamentSingleStatusMobile}
              />
              <Periodic
                type={tournament.type}
                state={state}
              />
              <Private
                accessType={tournament.accessType}
                state={state}
              />
            </div>
          )
        }
      </div>

      <div className={styles.tournamentSingleHeaderRightSide}>
        <JoinButtonAndDateTimeCounterSingle
          isLoading={isLoading}
          tournament={tournament}
          widgetStatus={status}
          setWidgetStatus={setStatus}
        />
      </div>
    </div>
  );
}
