import { useEffect, useState } from 'react';
import useTournamentAPI from 'apis/useTournamentAPI';

let timerInterval = null;

export const useGetTournamentRealStatus = (tournament, setStatus) => {
  const [loading, setLoading] = useState(false);
  const { getTournament } = useTournamentAPI();

  const getTournamentRealStatus = (statusReal) => {
    setLoading(true);

    timerInterval = setInterval(async () => {
      const tournamentData = await getTournament(tournament.id, tournament.periodId);
      const tournamentStatusNow = tournamentData.data?.tournament?.status;

      if (tournamentStatusNow === statusReal) {
        setStatus(statusReal);
        setLoading(false);
        clearInterval(timerInterval);
      }
    }, 5000);
  };

  useEffect(() => () => clearInterval(timerInterval), []);

  return { loading, getTournamentRealStatus };
};