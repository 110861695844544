import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { CrossIcon } from 'lib/Icons';
import useTournamentAPI from 'apis/useTournamentAPI';
import { msgToParent } from 'services/processPostMessage/ProcessPostMessage';
import styles from './modal.module.less';
import { mobileAndTabletCheck } from '../../../../utils/helper';
import { AuthContext } from '../../../../context/AuthContext';

const DESKTOP_TYPE = 1;
const MOBILE_TYPE = 2;

export default (props) => {
  const { gameId, mode, gameName, onClose } = props;
  const [state] = useContext(AuthContext);
  const [launchUrl, setLaunchUrl] = useState(null);
  const { getGameLaunchUrl } = useTournamentAPI();

  const deviceType = useMemo(() => {
    if (state.deviceType) return Number(state.deviceType);
    return mobileAndTabletCheck() ? MOBILE_TYPE : DESKTOP_TYPE;
  }, [state.deviceType]);

  useEffect(() => {
    const getData = async () => {
      const gameLaunchUrl = await getGameLaunchUrl(deviceType, mode, gameId);

      if (gameLaunchUrl?.hasError === false) {
        setLaunchUrl(gameLaunchUrl.data);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const handleBackToHome = (event) => {
      const { type } = event.data;
      if (type === 'rgs-backToHome') {
        onClose();
      }
    };

    window.addEventListener('message', handleBackToHome);
    msgToParent({ type: 'scrollto', value: 0 });
    return () => {
      window.removeEventListener('message', handleBackToHome);
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={styles.gameLaunchWrapper}>
      <div className={styles.gameLaunchBox}>

        <div className={styles.gameLaunchHeader}>
          <div className={styles.gameLaunchHeaderLeftSide}>
            <div className={styles.gameLaunchHeaderTitle}>
              {gameName}
            </div>
          </div>

          <div className={styles.gameLaunchHeaderRightSide}>
            <div onClick={onClose} className={styles.gameLaunchHeaderRightItem}>
              <CrossIcon />
            </div>
          </div>
        </div>

        <div className={styles.gameLaunchBody}>
          {launchUrl !== null && <iframe src={launchUrl} allowFullScreen />}
        </div>

      </div>
    </div>,
    document.body,
  );
}
