import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from './styles.module.less';
import { getWidgetStatus } from '../../enums/tournament';
import { AuthContext } from '../../context/AuthContext';
import { CanceledIcon } from '../../lib/Icons';
import { CustomTooltip } from '../Tooltip/Tooltip';

const Status = (props) => {
  const { status, className = null, notice } = props;
  const [state] = useContext(AuthContext);
  return (
    <span className={classNames(className ?? styles.tournamentItemStatus, styles[`tournamentItemStatus${status}`])}>
        {getWidgetStatus(status, state)}
      {notice && < CustomTooltip title={notice} key='color'>
          <span className={styles.tooltipContent} onClick={(e) => e.stopPropagation()}>
          <CanceledIcon />
          </span>
      </CustomTooltip>}
    </span>
  );
};

export default Status;
