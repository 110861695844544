import React, {useContext, useState} from 'react';
import DummyImg from 'assets/img/dummy-img.png';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';
import {Col} from 'antd';
import Status from 'components/Status';
import useGetBreakpoints from 'lib/hooks/useGetBreakpoints/useGetBreakpoints';
import {AuthContext} from 'context/AuthContext';
import {formatNumber} from 'utils/helper';
import styles from './styles.module.less';
import {useParseRules} from '../../../../lib/hooks/useParseRules';
import {Private} from '../../../TournamentSingle/components/Private';
import Periodic from '../../../TournamentSingle/Periodic';
import JoinButtonAndDateTimeCounter from './JoinButtonAndDateTimeCounter';
import {msgToParent} from '../../../../services/processPostMessage/ProcessPostMessage';

export default ({tournament, isGrid, playerLoggedIn}) => {
  const [state] = useContext(AuthContext);
  const {desktopImageUrl, name, prizePool, currency, notice, accessType, rules} = tournament;
  const navigate = useNavigate();
  const {checkBreakpoint} = useGetBreakpoints();
  const [status, setStatus] = useState(tournament.status);

  const handleMore = () => {
    if (!state?.testEnv) {
      const path = `/tournament/${tournament.id}/Periods/${tournament.periodId}`;
      msgToParent({type: 'currentURL', value: encodeURIComponent(path)});
      navigate(path);
    }
  };

  const isTablet = checkBreakpoint(['sm', 'md']);
  const isTabletSm = checkBreakpoint('sm');

  return (
    <Col
      className={classNames(isGrid ? '' : styles.tournamentItemContainer, {'col-33 col-lg-50 col-md-50': isGrid}, 'col-sm-100')}>
      <div className={classNames(styles.tournamentItem, {[styles.tournamentItemGrid]: isGrid})} onClick={handleMore}>

        <div className={styles.tournamentItemImageSide}>
          <img
            src={desktopImageUrl || DummyImg}
            alt={name}
            loading='lazy'
          />
          {isTablet && (
            <div className={styles.tabletPrizeType}>
              <span className={styles.tabletPrizeTypeTitle}>{state.translations?.prizePool}</span>
              <span className={styles.tabletPrizeTypeAmount}>
                  {formatNumber(prizePool, currency)}
                </span>
            </div>
          )}
        </div>

        <div className={styles.tournamentItemContentSide}>
          <div className={styles.tournamentItemTitle}>
            <div className={styles.tournamentItemTitleLeftSide}>
              <span className={styles.tournamentItemTitleSpan}>{name}</span>
              <Status status={status} notice={notice} />
              <div className={styles.tournamentItemIconsContainer}>
                <Periodic
                  type={tournament?.type}
                  state={state}
                />
                <Private
                  accessType={accessType}
                  state={state}
                />
              </div>
            </div>

          </div>

          <div className={styles.tournamentItemContent}>
            <div className={styles.tournamentItemContentText}>
              {useParseRules(rules)}
            </div>
          </div>

          <div className={styles.tournamentItemBottom}>
            {!isTablet && (
              <div className={styles.tournamentItemBottomLeftSide}>
                <div className={styles.tournamentItemPrizeType}>
                  <span className={styles.tournamentItemPrizeTypeTitle}>{state.translations?.prizePool}</span>
                  <span className={styles.tournamentItemPrizeTypeAmount}>
                    {formatNumber(prizePool, currency)}
                  </span>
                </div>
              </div>
            )}

            <div className={styles.tournamentItemBottomRightSide}>
              <JoinButtonAndDateTimeCounter
                tournament={tournament}
                status={status}
                setStatus={setStatus}
                playerLoggedIn={playerLoggedIn}
                handleMore={handleMore}
                isTabletSm={isTabletSm}
              />
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
