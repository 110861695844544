import React from 'react';
import { ACCESS_TYPE_PRIVATE } from '../../../enums/tournament';
import { PrivateIcon } from '../../../lib/Icons';
import styles from '../private.module.less';

export const Private = ({ accessType, state }) => (
  <>
    {ACCESS_TYPE_PRIVATE === accessType && (
      <div className={styles.typeStatus}>
        <PrivateIcon />
        <span className={styles.statusName}>{state.translations?.private}</span>
      </div>
    )}
  </>
);