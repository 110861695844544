import { useContext, useState } from 'react';
import useTournamentAPI from '../../../apis/useTournamentAPI';
import { AuthContext } from '../../../context/AuthContext';
import { setTournamentJoinedStatus } from '../../../context/reducerActions';
import { PLAYER_STATUS_JOINED, PLAYER_STATUS_REMOVED, PLAYER_STATUS_UNJOINED } from '../../../enums/tournament';
import { msgToParent } from '../../../services/processPostMessage/ProcessPostMessage';

const PLAY_TYPE_REAL = 'real';

export const useVerifyAndJoin = (props) => {
  const {
    tournament, open, setPopconfirmProps, playerLoggedIn,
    handleMore = null, openGame = null, isFromGames = false
  } = props;

  const [isLoadingBtn, setLoading] = useState(false);
  const { verifyPlayer, registerPlayer } = useTournamentAPI();
  const [state, dispatch] = useContext(AuthContext);

  const handleRegisterPlayer = async () => {
    const registerPlayerData = await registerPlayer(tournament.id, tournament.periodId);
    if (registerPlayerData?.hasError === false) {
      if (handleMore) {
        handleMore();
      } else {
        setTournamentJoinedStatus(dispatch, PLAYER_STATUS_JOINED);
        if (openGame) {
          openGame(PLAY_TYPE_REAL);
        }
      }
    } else {
      setPopconfirmProps(state => ({
        ...state,
        open: true,
        joinPopUp: true,
        hasCancelButton: false,
        text: registerPlayerData?.data?.joinPopUp,
        okText: state.translations?.ok,
        onConfirm: () => {
          setPopconfirmProps(state => ({
            ...state,
            open: false,
          }));
        },
      }));
    }
  };

  const handleJoin = async () => {
    if (state.testEnv) return true;

    if (!open) {
      if (!playerLoggedIn) {
        msgToParent({ type: 'openLogin' });
      } else if (tournament.joinedStatus === PLAYER_STATUS_UNJOINED || tournament.joinedStatus === PLAYER_STATUS_REMOVED) {
        setLoading(true);
        const verifyPlayerData = await verifyPlayer(tournament.id, tournament.periodId);
        if (verifyPlayerData?.hasError === false) {
          await handleRegisterPlayer();

        } else if (verifyPlayerData?.data?.joinPopUp !== null) {

          setPopconfirmProps({
            open: true,
            joinPopUp: true,
            text: verifyPlayerData?.data?.joinPopUp,
            okText: state.translations?.ok,
            hasCancelButton: false,
            onConfirm: () => {
              setPopconfirmProps(state => ({
                ...state,
                open: false,
              }));
            },
          });
        } else if (verifyPlayerData?.data?.freezePointsPopUp !== null) {

          setPopconfirmProps({
            open: true,
            text: verifyPlayerData?.data?.freezePointsPopUp,
            okText: state.translations?.ok,
            hasCancelButton: true,
            onConfirm: () => {
              setPopconfirmProps(state => ({
                ...state,
                open: false,
              }));
              handleRegisterPlayer();
            },
          });
        }

        if (isFromGames && verifyPlayerData?.hasError === false) {
          handleRegisterPlayer();
        }
        setLoading(false);
      }
    }
  };
  return { handleRegisterPlayer, handleJoin, isLoadingBtn };
};