export default {
  classic: {
    '--button-border-radius': '8px',
    '--item-card-border-radius': '8px',
    '--item-image-border-radius': '6px',
    '--item-tag-border-radius': '2px',
    '--item-button-border-radius': '4px',
    '--leaderborad-card-border-radius': '6px',
    '--rules-card-border-radius': '8px',
    '--games-card-border-radius': '6px',
    '--games-image-border-radius': '4px',
  },
  standard: {
    '--button-border-radius': '8px',
    '--item-card-border-radius': '16px',
    '--item-image-border-radius': '12px',
    '--item-tag-border-radius': '4px',
    '--item-button-border-radius': '6px',
    '--leaderborad-card-border-radius': '12px',
    '--rules-card-border-radius': '16px',
    '--games-card-border-radius': '12px',
    '--games-image-border-radius': '8px',
  },
  rounded: {
    '--button-border-radius': '8px',
    '--rules-card-border-radius': '24px',
    '--item-card-border-radius': '24px',
    '--item-image-border-radius': '18px',
    '--item-tag-border-radius': '12px',
    '--item-button-border-radius': '24px',
    '--leaderborad-card-border-radius': '18px',
    '--games-card-border-radius': '18px',
    '--games-image-border-radius': '12px',
  },
};