import React, { useContext } from 'react';
import styles from './styles.module.less';
import { AuthContext } from '../../../../context/AuthContext';
import NotItemsSvg from './NotItemsSvg';
import { WIDGET_STATUS_ENDED, WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING } from '../../../../enums/tournament';

const getNoTournamentsText = (state, filterStatus) => {
  const texts = {
    [WIDGET_STATUS_LIVE]: state.translations?.noTournamentsLive,
    [WIDGET_STATUS_UPCOMING]: state.translations?.noTournamentsUpcoming,
    [WIDGET_STATUS_ENDED]: state.translations?.noTournamentsEnded,
  };
  return texts[filterStatus] ?? state.translations?.noTournamentsAll;
};

const NotTournaments = (props) => {
  const { filterStatus } = props;
  const [state] = useContext(AuthContext);

  return (
    <div className={styles.notTournamentsContainer}>
      <div className={styles.notTournamentsContent}>
        <div className={styles.notTournamentsIcon}>
          <NotItemsSvg />
        </div>
        <div className={styles.notTournamentsTextContainer}>
          <p className={styles.noTournamentsText}> {getNoTournamentsText(state, filterStatus)} </p>
        </div>
      </div>
    </div>
  );
};

export default NotTournaments;