import useFetch from 'apis/useFetch';
import { useSearchParams } from 'react-router-dom';
import { tournament, tournaments, translations, games, leaderboard } from '../../enums/mockData';

export default () => {
  const [searchParams] = useSearchParams();
  const customFetch = useFetch();

  const isTestEnv = searchParams.has('testEnv');

  const getTournaments = () => {
    if (isTestEnv) {
      return new Promise((resolve) => resolve(tournaments));
    }

    return customFetch({
      url: `Widget/Tournaments`,
      method: 'GET',
    });
  };

  const getTranslations = () => {
    if (isTestEnv) {
      return new Promise((resolve) => resolve(translations));
    }

    return customFetch({
      url: `Widget/Translations`,
      method: 'GET',
    });
  };

  const getTournament = (tournamentId, periodId) => {
    if (isTestEnv) {
      return new Promise((resolve) => resolve(tournament));
    }

    return customFetch({
      url: `Widget/Tournaments/${tournamentId}/Periods/${periodId}`,
      method: 'GET',
    });
  };


  const getLeaderboard = (tournamentId, periodId) => {
    if (isTestEnv) {
      return new Promise((resolve) => resolve(leaderboard));
    }

    let periodPath = '';
    if (periodId) {
      periodPath = `/Period/${periodId}`;
    }

    return customFetch({
      url: `Widget/Leaderboard/Tournament/${tournamentId}${periodPath}`,
      method: 'GET',
    });
  };

  const getGames = (data) => {
    if (isTestEnv) {
      return new Promise((resolve) => resolve(games));
    }


    return customFetch({
      url: `Widget/PeriodGames`,
      method: 'POST',
      data,
    });
  };

  const verifyPlayer = (tournamentId, periodId) => {
    return customFetch({
      url: `Widget/VerifyJoin/${tournamentId}/${periodId}`,
      method: 'GET',
    });
  };

  const registerPlayer = (tournamentId, periodId) => {
    return customFetch({
      url: `Widget/Join/${tournamentId}/${periodId}`,
      method: 'GET',
    });
  };

  const getGameLaunchUrl = (deviceType, mode, gameId) => {
    return customFetch({
      url: `Widget/GetGameLaunchUrl/${deviceType}/${mode}/${gameId}`,
      method: 'GET',
    });
  };

  return {
    getTournaments, getTranslations, getTournament, getLeaderboard,
    getGames, verifyPlayer, registerPlayer, getGameLaunchUrl,
  };
}
