import openNotification from 'lib/Notification';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { dispatchSetAuthToken } from '../context/reducerActions';

export default () => {
  const [state, dispatch] = useContext(AuthContext);

  const { languageId, authToken } = state;
  const MAIN_DOMAIN = `${process.env.REACT_APP_URL_MAIN}/V1`;

  return ({ url, method, data = {}, signal = null }) => {
    const headers = {
      'LanguageId': languageId,
      'Content-Type': 'application/json',
    };

    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
      headers.LanguageId = languageId;
    }

    return fetch(`${MAIN_DOMAIN}/${url}`, {
      method,
      signal,
      cache: 'no-cache',
      headers,
      ...(method !== 'GET' && { body: JSON.stringify(data) }),
    }).then(async (response) => {

      if ([200, 400].includes(response.status)) {
        const newToken = response.headers.get('X-Refreshed-Token');

        if (newToken && authToken !== newToken) {
          dispatchSetAuthToken(dispatch, newToken);
        }


        return response.json();
      }

      throw new Error(response.statusText);
    }).catch((err) => {
      if (String(err).includes('AbortError')) return;
      openNotification('error', 'Error', `${err}`);
    });
  };
}