import React, { useContext, useState } from 'react';
import { Spin } from 'antd';
import { useGetTournamentRealStatus } from 'lib/hooks/useGetTournamentRealStatus';
import { WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING } from 'enums/tournament';
import DateTimeCounter from 'components/DateTimeCounter';
import JoinButton from 'components/JoinButton';
import PopConfirm from '../../../../components/PopConfirm/PopConfirm';
import { AuthContext } from '../../../../context/AuthContext';
import { useVerifyAndJoin } from '../../../../components/JoinButton/hooks';
import styles from './styles.module.less';

const JoinButtonAndDateTimeCounter = ({ tournament, status, setStatus, playerLoggedIn, handleMore, isTabletSm }) => {
  const hasTime = [WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING].includes(status);
  const [state] = useContext(AuthContext);

  const [popconfirmProps, setPopconfirmProps] = useState({
    open: null,
    text: '',
    hasJoinPopUp: false,
    onConfirm: () => {
    },
    hasCancelButton: false,
  });
  const { open, onConfirm, text, hasCancelButton } = popconfirmProps;
  const { loading, getTournamentRealStatus } = useGetTournamentRealStatus(tournament, setStatus);
  const onCancel = () => {
    setPopconfirmProps({ ...popconfirmProps, open: false });
  };

  const isDisplaySubmit = onConfirm !== null;

  const {
    handleJoin,
    isLoading,
  } = useVerifyAndJoin({ tournament, open, setPopconfirmProps, playerLoggedIn, handleMore });

  return (
    <Spin spinning={loading}>
      {
        hasTime ? (
          <DateTimeCounter
            tournament={tournament}
            widgetStatus={status}
            setWidgetStatus={setStatus}
            getTournamentRealStatus={getTournamentRealStatus}
          />
        ) : null
      }

      <div onClick={e => e.stopPropagation()} className={isTabletSm ? styles.btnContainer : ''}>
        {isDisplaySubmit ?
          <PopConfirm
            icon={null}
            open={open}
            onConfirm={onConfirm}
            onCancel={onCancel}
            text={text}
            hasCancelButton={hasCancelButton}
            setPopconfirmProps={setPopconfirmProps}
          >
            <JoinButton
              playerLoggedIn={playerLoggedIn}
              widgetStatus={status}
              tournament={tournament}
              handleJoin={handleJoin}
              isLoading={isLoading}
              isTabletSm={isTabletSm}
            />
          </PopConfirm> :
          <PopConfirm
            cancelText={state.translations?.cancel}
            onCancel={onCancel}
            setPopconfirmProps={setPopconfirmProps}
          />
        }
      </div>
    </Spin>
  );
};


export default JoinButtonAndDateTimeCounter;