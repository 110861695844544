import React from 'react';
import styles from './notice.module.less';
import { CancelledIconSingle } from '../../../lib/Icons';

const Notice = ({ notice }) => notice && (
  <div className={styles.noticeContainer}>
    <div className={styles.noticeContent}>
      <div className={styles.noticeIcon}>
        <CancelledIconSingle />
      </div>
      <div className={styles.noticeText}>
        <span>{notice}</span>
      </div>
    </div>
  </div>
);

export default Notice;