import React, { useContext } from 'react';
import { Popconfirm as PopconfirmAntd } from 'antd';
import styles from './styles.module.less';
import { AuthContext } from '../../context/AuthContext';

const PopConfirm = (props) => {
  const { open, text, hasCancelButton, isFromGames, setPopconfirmProps, okText, ...rest } = props;
  const [state] = useContext(AuthContext);


  return (
    <PopconfirmAntd
      open={open}
      overlayClassName={styles.popconfirmContainer}
      placement='topRight'
      onOpenChange={() => setPopconfirmProps((state) => ({
        ...state,
        open: false,
      }))}
      okButtonProps={{
        block: true,
      }}
      cancelButtonProps={{
        block: true,
      }}
      title={
        <div className={styles.popoverContent}>
          <div className={styles.popoverHeader}>
            <h2>{state.translations?.attention}</h2>
          </div>
          <div className={styles.popoverBody}>
            <span
              className={styles.popoverText}
              dangerouslySetInnerHTML={{ __html: text }}
              style={{ backgroundColor: 'none' }}
            />
          </div>
        </div>
      }
      okText={okText ?? state.translations?.ok}
      cancelText={state.translations?.cancel}
      showCancel={hasCancelButton}
      {...rest}
    />
  );
};


export default PopConfirm;
