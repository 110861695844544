import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.less';
import { AuthContext } from '../../context/AuthContext';
import {
  getPlayerStatus, WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING,
} from '../../enums/tournament';

import Button from '../Button/Button';


const JoinButton = (props) => {
  const {
    tournament, extraClass = '', widgetStatus, isFromGames = false, handleJoin, isLoading, isTabletSm,
  } = props;

  const [state] = useContext(AuthContext);
  const btnRef = useRef();

  const hasJoinButton = [WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING].includes(widgetStatus);

  const playerStatus = Object.keys(tournament).length > 0 && tournament.joinedStatus;

  return playerStatus !== null && hasJoinButton && (
    <>
      <Button
        type='button'
        onClick={handleJoin}
        loading={isLoading}
        ref={btnRef}
        className={classNames(styles.joinBtn, styles[`joinBtn${playerStatus}`], extraClass, isFromGames && styles.btn, isTabletSm && styles.joinBtnSm)}
      >
        {isFromGames ? state.translations?.join : getPlayerStatus(playerStatus, state)}
      </Button>
    </>
  );
};

export default JoinButton;
