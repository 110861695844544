import React, { useContext } from 'react';
import classNames from 'classnames';
import { AuthContext } from 'context/AuthContext';
import styles from '../styles.module.less';
import { LeaderboardTable } from './leaderboards/LeaderboardTable';
import { PrizesTable } from './leaderboards/PrizesTable';

const LeaderboardsAndPrizes = (props) => {
  const { leaderboardData = [], myScore = {}, currency, prizes = [] } = props;
  const [state] = useContext(AuthContext);

  return (
    <div className={classNames(styles.tournamentSingleLeaderboardPrizes, 'row-gutter-24 row-gutter-md-16')}>
      <div className='col-50 col-sm-100 col-gutter-24 col-gutter-md-16'>
        <div className={styles.tournamentSingleLeaderboardPrizesItem}>
          <div className={styles.tournamentSingleLeaderboardPrizesItemTitle}>
            {state.translations?.tournamentLeaderboard}
          </div>
          <div className={styles.tournamentSingleLeaderboardPrizesItemTable}>
            <LeaderboardTable
              state={state}
              leaderboardData={leaderboardData}
              prizes={prizes}
              myScore={myScore}
              currency={currency}
            />
          </div>
        </div>
      </div>

      <div className='col-50 col-sm-100 col-gutter-24 col-gutter-md-16'>
        <div className={styles.tournamentSingleLeaderboardPrizesItem}>
          <div className={styles.tournamentSingleLeaderboardPrizesItemTitle}>
            {state.translations?.tournamentPrizes}
          </div>
          <div className={styles.tournamentSingleLeaderboardPrizesItemTable}>
            <PrizesTable
              state={state}
              prizes={prizes}
              currency={currency}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardsAndPrizes;