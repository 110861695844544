import React from 'react';
import { Row } from 'antd';
import { gutterStandard } from 'utils/common';
import styles from '../../styles.module.less';
import useGetBreakpoints from '../../../../lib/hooks/useGetBreakpoints';
import LeaderboardItem from './LeaderboardItem';

const Leaderboards = (props) => {
  const { leaderboardData = [], currency } = props;
  const { breakpoint } = useGetBreakpoints();
  const leaderboardsArray = JSON.parse(JSON.stringify(leaderboardData)).slice(0, 4);

  const isDisplay = leaderboardsArray?.length > 0;

  return isDisplay && (
    <div className={styles.tournamentSingleTopLeaderboard}>
      <Row gutter={[gutterStandard[breakpoint], gutterStandard[breakpoint]]} className={styles.leaderboardRow}>
        {leaderboardsArray.map((leaderboard, index) => (
          <LeaderboardItem
            key={leaderboard.id}
            itemIndex={index}
            currency={currency}
            leaderboard={leaderboard}
          />
        ))}
      </Row>
    </div>
  );
};

export default Leaderboards;