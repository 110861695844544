import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthContext';
import {
  WIDGET_STATUS_ENDED,
  WIDGET_STATUS_UPCOMING,
  WIDGET_STATUS_LIVE,
} from 'enums/tournament';
import classNames from 'classnames';
import styles from './styles.module.less';
import useGetBreakpoints from '../../lib/hooks/useGetBreakpoints';

const DateTimeCounter = (props) => {
  const {
    tournament, widgetStatus, setWidgetStatus,
    isSingle = false, extraClass = '', getTournamentRealStatus,
  } = props;
  const [state] = useContext(AuthContext);
  const { checkBreakpoint } = useGetBreakpoints();
  const isSmOrMd = checkBreakpoint(['sm', 'md']);

  let timer;

  const setZero = number => String(number).length === 1 ? `0${number}` : number;

  const getData = (status) => {
    let title;
    let seconds = 0;

    if (status === WIDGET_STATUS_UPCOMING) {
      title = state.translations?.startsIn;
      seconds = tournament.startDateSecond;
    } else if (status === WIDGET_STATUS_LIVE) {
      title = state.translations?.endsIn;
      seconds = tournament.endDateSecond;
    }

    return { title, seconds };
  };

  const dhm = useCallback((second) => {
    const days = Math.floor(second / (3600 * 24));
    const hours = Math.floor((second % (3600 * 24)) / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = second % 60;

    return {
      day: setZero(days),
      hour: setZero(hours),
      minute: setZero(minutes),
      second: setZero(seconds),
    };
  }, []);

  const data = getData(widgetStatus);
  const { title } = data;

  const [datetime, setDatetime] = useState(data.seconds);

  const humanDateTime = {
    day: dhm(datetime).day,
    hour: dhm(datetime).hour,
    minute: dhm(datetime).minute,
    second: dhm(datetime).second,
  };

  const updateCount = () => {
    timer = !timer && setInterval(() => {
      setDatetime((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    setDatetime(data.seconds);
  }, [tournament, widgetStatus]);

  useEffect(() => {
    if (datetime > 0) {
      updateCount();
    } else if (widgetStatus === WIDGET_STATUS_UPCOMING) {
      if (datetime === 0) {
        getTournamentRealStatus(WIDGET_STATUS_LIVE);
      } else {
        setDatetime(getData(WIDGET_STATUS_LIVE).seconds);
        setWidgetStatus(WIDGET_STATUS_LIVE);
      }
    } else if (WIDGET_STATUS_LIVE === widgetStatus) {
      if (datetime === 0) {
        getTournamentRealStatus(WIDGET_STATUS_ENDED);
      } else {
        setWidgetStatus(WIDGET_STATUS_ENDED);
      }
    }

    return () => clearInterval(timer);
  }, [datetime]);

  return (
    <div
      className={classNames(
        { [styles.singleWrapper]: isSingle },
        styles.tournamentItemStartDateWrapper,
        'tournamentItemStartDateWrapper',
        extraClass,
      )}
    >
      <div className={classNames(styles.tournamentItemStartDateTitle, 'tournamentItemStartDateTitle')}>{title}</div>

      <div className={classNames(styles.tournamentItemStartDate, 'tournamentItemStartDate')}>
        <div className={styles.tournamentItemStartDateItem}>
          <span className={styles.tournamentItemStartDateTimeNumber}>{humanDateTime.day}</span>
          <span className={styles.tournamentItemStartDateTimeDay}>{state.translations?.day}</span>
        </div>

        <div className={styles.tournamentItemStartDateItem}>
          <span className={styles.tournamentItemStartDateTimeNumber}>{humanDateTime.hour}</span>
          <span className={styles.tournamentItemStartDateTimeDay}>{state.translations?.hour}</span>
        </div>

        <div className={styles.tournamentItemStartDateItem}>
          <span className={styles.tournamentItemStartDateTimeNumber}>{humanDateTime.minute}</span>
          <span className={styles.tournamentItemStartDateTimeDay}>{state.translations?.min}</span>
        </div>

        <div
          className={classNames(styles.tournamentItemStartDateItem, { [styles.tournamentItemStartDateItemMobile]: isSmOrMd })}>
          <span className={styles.tournamentItemStartDateTimeNumber}>{humanDateTime.second}</span>
          <span className={styles.tournamentItemStartDateTimeDay}>{state.translations?.sec}</span>
        </div>
      </div>
    </div>
  );
};

export default DateTimeCounter;
