import { Tooltip as TooltipAntd } from 'antd';
import styles from './styles.module.less';

export const CustomTooltip = (props) => {
  const { children, ...rest } = props;
  return (
    <TooltipAntd
      overlayClassName={styles.tooltipContainer}
      {...rest}
    >
      {children}
    </TooltipAntd>
  );
};