import React, { useContext, useState } from 'react';
import { Form, Input } from 'antd';
import { ClearCircleIcon, SearchIcon, CrossSecondIcon } from 'lib/Icons';
import useGetBreakpoints from 'lib/hooks/useGetBreakpoints';
import styles from '../../styles.module.less';
import { AuthContext } from '../../../../context/AuthContext';

export default ({ getGames, setPageNumber }) => {
  const [state] = useContext(AuthContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const { checkBreakpoint } = useGetBreakpoints();
  const form = Form.useFormInstance();
  const searchText = form.getFieldValue('search');
  const handleExpandable = () => {
    setIsExpanded(!isExpanded);

    if (isExpanded) {
      if (searchText) {
        setPageNumber(1);
        getGames(1, '');
      }
    }
  };

  const isMobile = checkBreakpoint('sm');

  return (
    !isMobile ? (
      <div className={styles.searchBox}>
        <Form.Item name='search'>
          <Input
            className={styles.searchElement}
            placeholder={state.translations?.search}
            prefix={<SearchIcon />}
            allowClear={{
              clearIcon: <ClearCircleIcon />,
            }}
          />
        </Form.Item>
      </div>
    ) : (
      <div className={styles.searchBoxMobile}>
        {isExpanded && (
          <div className={styles.searchInputWrapper}>
            <Form.Item name='search'>
              <Input
                className={styles.searchElement}
                placeholder={state.translations?.search}
                allowClear={{
                  clearIcon: <ClearCircleIcon />,
                }}
              />
            </Form.Item>
          </div>
        )}

        <div className={styles.searchBoxBtn} onClick={handleExpandable}>
          {isExpanded ? <CrossSecondIcon /> : <SearchIcon />}
        </div>
      </div>
    )
  );
}
