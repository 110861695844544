import { useEffect, useState } from 'react';

export default () => {
  const getBreakpoint = (innerWidth) => {
    let breakpointStr = '';
    if (innerWidth > 1601) {
      breakpointStr = 'xxl';
    } else if (innerWidth >= 1281 && innerWidth <= 1601) {
      breakpointStr = 'xl';
    } else if (innerWidth >= 1025 && innerWidth <= 1280) {
      breakpointStr = 'lg';
    } else if (innerWidth >= 769 && innerWidth <= 1024) {
      breakpointStr = 'md';
    } else if (innerWidth <= 768) {
      breakpointStr = 'sm';
    }
    return breakpointStr;
  };
  const [breakpoint, setBreakpoint] = useState(getBreakpoint(window.innerWidth));

  useEffect(() => {
    window.addEventListener('resize', function() {
      const { innerWidth } = this;
      setBreakpoint(getBreakpoint(innerWidth));
    });
  }, []);

  return {
    breakpoint,
    checkBreakpoint: (screenSize) => (
      Array.isArray(screenSize) ? screenSize.includes(breakpoint) : screenSize === breakpoint
    ),
  };
}
