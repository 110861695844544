import { formatNumber } from '../utils/helper';

export const transformPrizesData = (leaderboardsArray, prizes, myScore, currency = 'Eur') => {
  let setMyPlace = false;

  leaderboardsArray.forEach((leaderboard, index) => {
    const place = index + 1;
    const prizeTypeId = prizes[index]?.prizeTypeId;
    leaderboard.place = place;
    leaderboard.prize = '-';

    if (
      (myScore !== null && Object.keys(myScore).length > 0) &&
      (myScore?.id === leaderboard.id || (!setMyPlace && place === leaderboardsArray.length))
    ) {
      if (myScore?.id === leaderboard.id) {
        leaderboard.isMyPlace = true;
        leaderboard.score = myScore?.score;
      } else {
        leaderboardsArray.push({
          isMyPlace: true,
          place: myScore?.place,
          score: myScore?.score,
          prize: '-',
          userName: myScore?.userName,
        });
      }
      setMyPlace = true;
    }

    if (prizes[index] !== undefined) {
      if (prizeTypeId === 2) {
        leaderboard.prize = formatNumber(prizes[index]?.description);
      } else if (prizeTypeId === 1) {
        leaderboard.prize = formatNumber(prizes[index]?.amount, currency);
      } else if (prizeTypeId === 3) {
        leaderboard.prize = formatNumber(prizes[index]?.quantity, 'x Free Round');
      }
    }
  });

  return leaderboardsArray;
};