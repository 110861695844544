import { useContext } from 'react';
import { decode } from '@msgpack/msgpack';
import { AuthContext } from '../../context/AuthContext';

let socket = null;
let intervalId = null;
export const useWebSocket = (props) => {
  const { tournamentId, leaderboardCount } = props;
  const [state] = useContext(AuthContext);
  const { authToken } = state;
  const wsSocket = `${process.env.REACT_APP_SOCKET_URL}/V1/Widget/ConnectWebSocket`;

  const connect = (cb, tournamentPeriodId) => {
    let paramPeriodId = `&TournamentPeriodId=${tournamentPeriodId}`;
    if (!tournamentPeriodId) {
      paramPeriodId = '';
    }

    socket = new WebSocket(`${wsSocket}?TournamentId=${tournamentId}${paramPeriodId}&JwtToken=${authToken}&LeaderboardCount=${leaderboardCount}`);
    socket.binaryType = 'arraybuffer';
    socket.onopen = () => {
      intervalId = setInterval(() => {
        socket.send('ping');
      }, 5000);

      console.log('WebSocket connected');
    };

    socket.onmessage = (event) => {
      const data = decode(event.data);
      if (data !== 'pong') {
        let dataIndex = 1;
        if (data[0] === 3 || data[0] === 4) dataIndex = 2;

        const type = dataIndex === 1 ? 'player' : 'leaderboards';
        let transformedData = data[dataIndex];

        if (type === 'leaderboards') {
          transformedData = transformedData[0].map((player) => ({
            id: player[0],
            userName: player[1],
            score: player[2],
          }));
        } else {
          transformedData = {
            place: transformedData[0],
            id: transformedData[2],
            score: transformedData[1],
            userName: transformedData[3],
          };
        }
        cb({ data: transformedData, type });
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      clearInterval(intervalId);
      console.log('WebSocket connection closed');
    };
  };

  const disconnect = () => {
    if (socket !== null) {
      socket.close();
      socket = null;
    }
  };

  return { connect, disconnect };
};