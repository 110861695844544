import React from 'react';
import { getPrizeType } from 'utils/helper';

export const PrizesTable = ({ state, prizes, currency }) => (
  <table>
    <thead>
    <tr>
      <th className='th_1'>#</th>
      <th className='th_5'>{state.translations?.prize}</th>
    </tr>
    </thead>
    <tbody>
    {prizes.map((prize) => {
      if (prize.prizeTypeId) {
        return <tr key={prize.place}>
          <td className='td_1'>{prize.place}</td>
          <td className='td_5'>{getPrizeType(prize, currency, state.translations?.xFreeRound)}</td>
        </tr>;
      }
    })}
    </tbody>
  </table>
);