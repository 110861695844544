export const tournaments = {
  'hasError': false,
  'data': {
    'playerLoggedIn': true,
    'tournaments':[
      {
        "id": 288,
        "periodId": 592,
        "mobileImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/a25aa4db-4bb4-4cc4-b033-8282e5bc285f.webp",
        "desktopImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/f7c26881-05d8-45fe-abc8-064a5482fc68.webp",
        "name": "Friday Frenzy Showdown",
        "status": 2,
        "type": 1,
        "accessType": 1,
        "rules": "<ol><li><strong>Eligibility:</strong></li><li class=\"ql-indent-1\">Only registered and verified players are eligible to participate in the tournament.</li><li class=\"ql-indent-1\">Players must be of legal gambling age according to the laws of their jurisdiction.</li><li><strong>Participation:</strong></li><li class=\"ql-indent-1\">Players must Join to participate in the tournament.</li><li class=\"ql-indent-1\">Only wagers placed on selected games during the tournament period will count towards the tournament.</li><li><strong>Leaderboard:</strong></li><li class=\"ql-indent-1\">The leaderboard will be updated in real-time.</li><li class=\"ql-indent-1\">Points are accrued based on bet amount.</li><li><strong>Prizes:</strong></li><li class=\"ql-indent-1\">Prizes will be awarded based on the final leaderboard standings at the end of the tournament.</li><li><strong>Game Selection:</strong></li><li class=\"ql-indent-1\">The tournament includes the games from Amusnet, Pragmatic Play and Playson Providers</li><li class=\"ql-indent-1\"><span style=\"color: var(--ant-color-text); background-color: var(--surface1);\">Specific Conditions</span></li><li><strong>Privacy:</strong></li><li class=\"ql-indent-1\">By participating in the tournament, players agree to the use of their nickname and scores for promotional purposes.</li></ol>",
        "startDateSecond": 290415,
        "endDateSecond": 315615,
        "prizePool": 2850,
        "currency": "USD",
        "joinedStatus": 2,
        "leaderboardCount": 100,
        "comulative": false,
        "notice": null
      },
      {
        "id": 289,
        "periodId": 596,
        "mobileImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/79d48e1b-d6a6-4ee2-9006-b2870f9f8381.webp",
        "desktopImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/95ec8af5-d770-4d3c-b2c3-89acdedcaed7.webp",
        "name": "Elite Champions Clash",
        "status": 3,
        "type": 1,
        "accessType": 1,
        "rules": "<ol><li><strong>Eligibility:</strong></li><li class=\"ql-indent-1\">Only registered and verified players are eligible to participate in the tournament.</li><li class=\"ql-indent-1\">Players must be of legal gambling age according to the laws of their jurisdiction.</li><li><strong>Participation:</strong></li><li class=\"ql-indent-1\">Players must Join to participate in the tournament.</li><li class=\"ql-indent-1\">Only wagers placed on selected games during the tournament period will count towards the tournament.</li><li><strong>Leaderboard:</strong></li><li class=\"ql-indent-1\">The leaderboard will be updated in real-time.</li><li class=\"ql-indent-1\">Points are accrued based on bet amount.</li><li><strong>Prizes:</strong></li><li class=\"ql-indent-1\">Prizes will be awarded based on the final leaderboard standings at the end of the tournament.</li><li><strong>Game Selection:</strong></li><li class=\"ql-indent-1\">The tournament includes the games from Amusnet, Pragmatic Play and Playson Providers</li><li class=\"ql-indent-1\"><span style=\"color: var(--ant-color-text); background-color: var(--surface1);\">Specific Conditions</span></li><li><strong>Privacy:</strong></li><li class=\"ql-indent-1\">By participating in the tournament, players agree to the use of their nickname and scores for promotional purposes.</li></ol><p><br></p>",
        "startDateSecond": null,
        "endDateSecond": 488415,
        "prizePool": 2150,
        "currency": "USD",
        "joinedStatus": 2,
        "leaderboardCount": 50,
        "comulative": false,
        "notice": null
      },
      {
        "id": 290,
        "periodId": 597,
        "mobileImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/78646506-2f04-4fc5-a1ea-4f77eb0457b3.webp",
        "desktopImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/78646506-2f04-4fc5-a1ea-4f77eb0457b3.webp",
        "name": "Spin and Win Tournament",
        "status": 3,
        "type": 1,
        "accessType": 1,
        "rules": "<ol><li><strong>Eligibility:</strong></li><li class=\"ql-indent-1\">Only registered and verified players are eligible to participate in the tournament.</li><li class=\"ql-indent-1\">Players must be of legal gambling age according to the laws of their jurisdiction.</li><li><strong>Participation:</strong></li><li class=\"ql-indent-1\">Players must Join to participate in the tournament.</li><li class=\"ql-indent-1\">Only wagers placed on selected games during the tournament period will count towards the tournament.</li><li><strong>Leaderboard:</strong></li><li class=\"ql-indent-1\">The leaderboard will be updated in real-time.</li><li class=\"ql-indent-1\">Points are accrued based on bet amount.</li><li><strong>Prizes:</strong></li><li class=\"ql-indent-1\">Prizes will be awarded based on the final leaderboard standings at the end of the tournament.</li><li><strong>Game Selection:</strong></li><li class=\"ql-indent-1\">The tournament includes the games from All Providers</li><li class=\"ql-indent-1\"><span style=\"color: var(--ant-color-text); background-color: var(--surface1);\">Specific Conditions</span></li><li><strong>Privacy:</strong></li><li class=\"ql-indent-1\">By participating in the tournament, players agree to the use of their nickname and scores for promotional purposes.</li></ol><p><br></p>",
        "startDateSecond": null,
        "endDateSecond": 272415,
        "prizePool": 15300,
        "currency": "USD",
        "joinedStatus": 2,
        "leaderboardCount": 100,
        "comulative": false,
        "notice": null
      },
      {
        "id": 291,
        "periodId": 600,
        "mobileImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/fde3ee73-501c-40e9-b35e-dafeb2b561f9.webp",
        "desktopImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/fde3ee73-501c-40e9-b35e-dafeb2b561f9.webp",
        "name": "Champions Circuit",
        "status": 3,
        "type": 2,
        "accessType": 1,
        "rules": "<ol><li><strong>Eligibility:</strong></li><li class=\"ql-indent-1\">Only registered and verified players are eligible to participate in the tournament.</li><li class=\"ql-indent-1\">Players must be of legal gambling age according to the laws of their jurisdiction.</li><li><strong>Participation:</strong></li><li class=\"ql-indent-1\">Players must Join to participate in the tournament.</li><li class=\"ql-indent-1\">Only wagers placed on selected games during the tournament period will count towards the tournament.</li><li><strong>Leaderboard:</strong></li><li class=\"ql-indent-1\">The leaderboard will be updated in real-time.</li><li class=\"ql-indent-1\">Points are accrued based on bet amount.</li><li><strong>Prizes:</strong></li><li class=\"ql-indent-1\">Prizes will be awarded based on the final leaderboard standings at the end of the tournament.</li><li><strong>Game Selection:</strong></li><li class=\"ql-indent-1\">The tournament includes the games from Amusnet, Pragmatic Play and Playson Providers</li><li class=\"ql-indent-1\"><span style=\"background-color: var(--surface1); color: var(--ant-color-text);\">Specific Conditions</span></li><li><strong>Privacy:</strong></li><li class=\"ql-indent-1\">By participating in the tournament, players agree to the use of their nickname and scores for promotional purposes.</li></ol>",
        "startDateSecond": null,
        "endDateSecond": 24015,
        "prizePool": 5235,
        "currency": "USD",
        "joinedStatus": 2,
        "leaderboardCount": 100,
        "comulative": true,
        "notice": null
      },
      {
        "id": 332,
        "periodId": 658,
        "mobileImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/78646506-2f04-4fc5-a1ea-4f77eb0457b3.webp",
        "desktopImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/78646506-2f04-4fc5-a1ea-4f77eb0457b3.webp",
        "name": "Sunday Night Spin Fest",
        "status": 2,
        "type": 1,
        "accessType": 1,
        "rules": "<ol><li><strong>Eligibility:</strong></li><li class=\"ql-indent-1\">Only registered and verified players are eligible to participate in the tournament.</li><li class=\"ql-indent-1\">Players must be of legal gambling age according to the laws of their jurisdiction.</li><li><strong>Participation:</strong></li><li class=\"ql-indent-1\">Players must Join to participate in the tournament.</li><li class=\"ql-indent-1\">Only wagers placed on selected games during the tournament period will count towards the tournament.</li><li><strong>Leaderboard:</strong></li><li class=\"ql-indent-1\">The leaderboard will be updated in real-time.</li><li class=\"ql-indent-1\">Points are accrued based on bet amount.</li><li><strong>Prizes:</strong></li><li class=\"ql-indent-1\">Prizes will be awarded based on the final leaderboard standings at the end of the tournament.</li><li><strong>Game Selection:</strong></li><li class=\"ql-indent-1\">The tournament includes the games from Amusnet, Pragmatic Play and Playson Providers</li><li class=\"ql-indent-1\"><span style=\"background-color: var(--surface1); color: var(--ant-color-text);\">Specific Conditions</span></li><li><strong>Privacy:</strong></li><li class=\"ql-indent-1\">By participating in the tournament, players agree to the use of their nickname and scores for promotional purposes.</li></ol>",
        "startDateSecond": 405615,
        "endDateSecond": 491715,
        "prizePool": 1110,
        "currency": "EUR",
        "joinedStatus": 2,
        "leaderboardCount": 50,
        "comulative": false,
        "notice": null
      },
      {
        "id": 333,
        "periodId": 659,
        "mobileImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/2ad5b48e-fcf7-470d-85f1-01a7e3372281.webp",
        "desktopImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/81e14a21-3016-4f87-ab6d-309b75b3405a.webp",
        "name": "Grand Theft Slots",
        "status": 2,
        "type": 2,
        "accessType": 1,
        "rules": "<ol><li><strong>Eligibility:</strong></li><li class=\"ql-indent-1\">Only registered and verified players are eligible to participate in the tournament.</li><li class=\"ql-indent-1\">Players must be of legal gambling age according to the laws of their jurisdiction.</li><li><strong>Participation:</strong></li><li class=\"ql-indent-1\">Players must Join to participate in the tournament.</li><li class=\"ql-indent-1\">Only wagers placed on selected games during the tournament period will count towards the tournament.</li><li><strong>Leaderboard:</strong></li><li class=\"ql-indent-1\">The leaderboard will be updated in real-time.</li><li class=\"ql-indent-1\">Points are accrued based on bet amount.</li><li><strong>Prizes:</strong></li><li class=\"ql-indent-1\">Prizes will be awarded based on the final leaderboard standings at the end of the tournament.</li><li><strong>Game Selection:</strong></li><li class=\"ql-indent-1\">The tournament includes the games from Amusnet, Pragmatic Play and Playson Providers</li><li class=\"ql-indent-1\"><span style=\"background-color: var(--surface1); color: var(--ant-color-text);\">Specific Conditions</span></li><li><strong>Privacy:</strong></li><li class=\"ql-indent-1\">By participating in the tournament, players agree to the use of their nickname and scores for promotional purposes.</li></ol><p><br></p>",
        "startDateSecond": 297615,
        "endDateSecond": 1680015,
        "prizePool": 3800,
        "currency": "USD",
        "joinedStatus": 2,
        "leaderboardCount": 100,
        "comulative": false,
        "notice": null
      },
      {
        "id": 335,
        "periodId": 663,
        "mobileImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/e0596215-cb47-4772-9a22-f3ab105c1cb2.webp",
        "desktopImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/e0596215-cb47-4772-9a22-f3ab105c1cb2.webp",
        "name": "Royal Rumble",
        "status": 4,
        "type": 1,
        "accessType": 1,
        "rules": "<ol><li><strong>Eligibility:</strong></li><li class=\"ql-indent-1\">Only registered and verified players are eligible to participate in the tournament.</li><li class=\"ql-indent-1\">Players must be of legal gambling age according to the laws of their jurisdiction.</li><li><strong>Participation:</strong></li><li class=\"ql-indent-1\">Players must Join to participate in the tournament.</li><li class=\"ql-indent-1\">Only wagers placed on selected games during the tournament period will count towards the tournament.</li><li><strong>Leaderboard:</strong></li><li class=\"ql-indent-1\">The leaderboard will be updated in real-time.</li><li class=\"ql-indent-1\">Points are accrued based on bet amount.</li><li><strong>Prizes:</strong></li><li class=\"ql-indent-1\">Prizes will be awarded based on the final leaderboard standings at the end of the tournament.</li><li><strong>Game Selection:</strong></li><li class=\"ql-indent-1\">The tournament includes the games from Amusnet, Pragmatic Play and Playson Providers</li><li class=\"ql-indent-1\"><span style=\"background-color: var(--surface1); color: var(--ant-color-text);\">Specific Conditions</span></li><li><strong>Privacy:</strong></li><li class=\"ql-indent-1\">By participating in the tournament, players agree to the use of their nickname and scores for promotional purposes.</li></ol><p><br></p>",
        "startDateSecond": null,
        "endDateSecond": null,
        "prizePool": 5200,
        "currency": "USD",
        "joinedStatus": 2,
        "leaderboardCount": 50,
        "comulative": false,
        "notice": null
      }
    ]
  },
};

export const tournament = {
  "hasError": false,
  "data": {
    "playerLoggedIn": false,
    "tournament": {
      "id": 333,
      "periodId": 659,
      "mobileImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/79d48e1b-d6a6-4ee2-9006-b2870f9f8381.webp",
      "desktopImageUrl": "https://cdn-rgs.dev-digi.com/Tournament/widget_images/95ec8af5-d770-4d3c-b2c3-89acdedcaed7.webp",
      "name": "Grand Theft Slots",
      "status": 2,
      "type": 2,
      "accessType": 1,
      "rules": "<ol><li><strong>Eligibility:</strong></li><li class=\"ql-indent-1\">Only registered and verified players are eligible to participate in the tournament.</li><li class=\"ql-indent-1\">Players must be of legal gambling age according to the laws of their jurisdiction.</li><li><strong>Participation:</strong></li><li class=\"ql-indent-1\">Players must Join to participate in the tournament.</li><li class=\"ql-indent-1\">Only wagers placed on selected games during the tournament period will count towards the tournament.</li><li><strong>Leaderboard:</strong></li><li class=\"ql-indent-1\">The leaderboard will be updated in real-time.</li><li class=\"ql-indent-1\">Points are accrued based on bet amount.</li><li><strong>Prizes:</strong></li><li class=\"ql-indent-1\">Prizes will be awarded based on the final leaderboard standings at the end of the tournament.</li><li><strong>Game Selection:</strong></li><li class=\"ql-indent-1\">The tournament includes the games from Amusnet, Pragmatic Play and Playson Providers</li><li class=\"ql-indent-1\"><span style=\"background-color: var(--surface1); color: var(--ant-color-text);\">Specific Conditions</span></li><li><strong>Privacy:</strong></li><li class=\"ql-indent-1\">By participating in the tournament, players agree to the use of their nickname and scores for promotional purposes.</li></ol><p><br></p>",
      "startDateSecond": 38590,
      "endDateSecond": 1420990,
      "prizePool": 3800,
      "currency": "USD",
      "joinedStatus": 2,
      "leaderboardCount": 100,
      "comulative": false,
      "notice": null
    },
    "periods": [
      {
        "periodId": 659,
        "periodName": "Vice City",
        "status": 2,
        "showDate": "2024-06-17T18:00:00Z",
        "hideDate": "2024-07-12T18:00:00Z",
        "startDate": "2024-06-21T18:00:00Z",
        "endDate": "2024-06-23T18:00:00Z"
      },
      {
        "periodId": 660,
        "periodName": "Liberty City",
        "status": 2,
        "showDate": "2024-06-17T18:00:00Z",
        "hideDate": "2024-07-12T18:00:00Z",
        "startDate": "2024-06-28T18:00:00Z",
        "endDate": "2024-06-30T18:00:00Z"
      },
      {
        "periodId": 661,
        "periodName": "San Andreas",
        "status": 2,
        "showDate": "2024-06-17T18:00:00Z",
        "hideDate": "2024-07-12T18:00:00Z",
        "startDate": "2024-07-05T18:00:00Z",
        "endDate": "2024-07-07T18:00:00Z"
      }
    ]
  }
};

export const translations = {
  'hasError': false,
  'data': [
      {
        "translationId": "tournament",
        "text": "Tournament"
      },
      {
        "translationId": "tournaments",
        "text": "Tournaments"
      },
      {
        "translationId": "allTournaments",
        "text": "All Tournaments"
      },
      {
        "translationId": "liveTournaments",
        "text": "Live Tournaments"
      },
      {
        "translationId": "upcomingTournaments",
        "text": "Upcoming Tournaments"
      },
      {
        "translationId": "endedTournaments",
        "text": "Ended Tournaments"
      },
      {
        "translationId": "startsIn",
        "text": "Starts in"
      },
      {
        "translationId": "endsIn",
        "text": "Ends in"
      },
      {
        "translationId": "day",
        "text": "Day"
      },
      {
        "translationId": "hour",
        "text": "Hour"
      },
      {
        "translationId": "minute",
        "text": "Minute"
      },
      {
        "translationId": "seconds",
        "text": "Seconds"
      },
      {
        "translationId": "prizeFund",
        "text": "Prize fund"
      },
      {
        "translationId": "tournamentGames",
        "text": "Tournament Games"
      },
      {
        "translationId": "tournamentRules",
        "text": "Tournament Rules"
      },
      {
        "translationId": "tournamentTermsAndConditions",
        "text": "Tournament Terms and Conditions"
      },
      {
        "translationId": "tournamentLeaderboard",
        "text": "Tournament Leaderboard"
      },
      {
        "translationId": "player",
        "text": "Player"
      },
      {
        "translationId": "score",
        "text": "Score"
      },
      {
        "translationId": "prize",
        "text": "Prize"
      },
      {
        "translationId": "tournamentPrizes",
        "text": "Tournament Prizes"
      },
      {
        "translationId": "moreTournaments",
        "text": "More Tournaments"
      },
      {
        "translationId": "joinTournament",
        "text": "Join Tournament"
      },
      {
        "translationId": "loadMoreGames",
        "text": "Load More Games"
      },
      {
        "translationId": "more",
        "text": "More"
      },
      {
        "translationId": "toWin",
        "text": "To Win"
      },
      {
        "translationId": "prizePool",
        "text": "Prize Pool"
      },
      {
        "translationId": "joined",
        "text": "Joined"
      },
      {
        "translationId": "frozen",
        "text": "Frozen"
      },
      {
        "translationId": "live",
        "text": "Live"
      },
      {
        "translationId": "upcoming",
        "text": "Upcoming"
      },
      {
        "translationId": "ended",
        "text": "Ended"
      },
      {
        "translationId": "myScore",
        "text": "My Score"
      },
      {
        "translationId": "place",
        "text": "Place"
      },
      {
        "translationId": "search",
        "text": "Search"
      },
      {
        "translationId": "play",
        "text": "Play"
      },
      {
        "translationId": "demo",
        "text": "Demo"
      },
      {
        "translationId": "rejoin",
        "text": "Rejoin"
      },
      {
        "translationId": "private",
        "text": "Private"
      },
      {
        "translationId": "min",
        "text": "Min"
      },
      {
        "translationId": "sec",
        "text": "Sec"
      },
      {
        "translationId": "demo",
        "text": "Demo"
      },
      {
        "translationId": "rejoin",
        "text": "Rejoin"
      },
      {
        "translationId": "private",
        "text": "Private"
      },
      {
        "translationId": "min",
        "text": "Min"
      },
      {
        "translationId": "sec",
        "text": "Sec"
      },
      {
        "translationId": "cancelled",
        "text": "Cancelled"
      },
      {
        "translationId": "joinAndPlay",
        "text": "Join and Play"
      },
      {
        "translationId": "xFreeRound",
        "text": "x Free Round"
      },
      {
        "translationId": "toCollectPointsJoinTheTournamentBeforePlayingTheGame",
        "text": "To collect points, join the tournament before playing the game."
      },
      {
        "translationId": "join",
        "text": "Join"
      },
      {
        "translationId": "periodic",
        "text": "Periodic"
      },
      {
        "translationId": "1stPlace",
        "text": "1st place"
      },
      {
        "translationId": "2ndPlace",
        "text": "2nd place"
      },
      {
        "translationId": "3rdPlace",
        "text": "3rd place"
      },
      {
        "translationId": "4thPlace",
        "text": "4th place"
      },
      {
        "translationId": "cancel",
        "text": "Cancel"
      },
      {
        "translationId": "ok",
        "text": "Ok"
      },
      {
        "translationId": "attention",
        "text": "Attention"
      },
      {
        "translationId": "jan",
        "text": "Jan"
      },
      {
        "translationId": "feb",
        "text": "Feb"
      },
      {
        "translationId": "mar",
        "text": "Mar"
      },
      {
        "translationId": "apr",
        "text": "Apr"
      },
      {
        "translationId": "may",
        "text": "May"
      },
      {
        "translationId": "jun",
        "text": "Jun"
      },
      {
        "translationId": "jul",
        "text": "Jul"
      },
      {
        "translationId": "aug",
        "text": "Aug"
      },
      {
        "translationId": "sep",
        "text": "Sep"
      },
      {
        "translationId": "oct",
        "text": "Oct"
      },
      {
        "translationId": "nov",
        "text": "Nov"
      },
      {
        "translationId": "dec",
        "text": "Dec"
      },
      {
        "translationId": "cumulative",
        "text": "Cumulative"
      }
  ],
};

export const leaderboard = {
  'hasError': false,
  'data': {
    'leaderboard': [
      {
        'userName': '3****9',
        'score': 4079590,
      },
      {
        'userName': '4*******1',
        'score': 1508900,
      },
    ],
    'myScore': null,
    'prizes': [
      {
        'prizeTypeId': 2,
        'place': 1,
        'description': 'iPhone 15 Pro Max',
        'amount': 1320,
        'quantity': 1,
      },
      {
        'prizeTypeId': 2,
        'place': 2,
        'description': 'iPhone 15',
        'amount': 1232,
        'quantity': 1,
      },
      {
        'prizeTypeId': 2,
        'place': 3,
        'description': 'iPhone 14 ',
        'amount': 1056,
        'quantity': 1,
      },
      {
        'prizeTypeId': 1,
        'place': 4,
        'description': '100',
        'amount': 88,
        'quantity': 1,
      },
      {
        'prizeTypeId': 1,
        'place': 5,
        'description': '100',
        'amount': 88,
        'quantity': 1,
      },
      {
        'prizeTypeId': 1,
        'place': 6,
        'description': '100',
        'amount': 88,
        'quantity': 1,
      },
      {
        'prizeTypeId': 1,
        'place': 7,
        'description': '100',
        'amount': 88,
        'quantity': 1,
      },
      {
        'prizeTypeId': 1,
        'place': 8,
        'description': '100',
        'amount': 88,
        'quantity': 1,
      },
      {
        'prizeTypeId': 1,
        'place': 9,
        'description': '100',
        'amount': 88,
        'quantity': 1,
      },
      {
        'prizeTypeId': 1,
        'place': 10,
        'description': '100',
        'amount': 88,
        'quantity': 1,
      },
    ],
  },
};

export const games = {
  "hasError": false,
  "data": {
    "totalCount": 1107,
    "games": [
      {
        "id": 1854,
        "providerId": 22,
        "categoryId": 6,
        "subCategoryId": 6,
        "name": "All American Poker 1 Hand",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/AllAmericanPoker.jpg"
      },
      {
        "id": 1855,
        "providerId": 22,
        "categoryId": 6,
        "subCategoryId": 6,
        "name": "All American Poker 50 Hand",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/AllAmericanPoker50Hand.jpg"
      },
      {
        "id": 1856,
        "providerId": 22,
        "categoryId": 6,
        "subCategoryId": 6,
        "name": "All American Poker 5 Hand",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/AllAmericanPoker5Hand.jpg"
      },
      {
        "id": 1858,
        "providerId": 22,
        "categoryId": 6,
        "subCategoryId": 6,
        "name": "American Baccarat",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/AmericanBaccarat.jpg"
      },
      {
        "id": 1859,
        "providerId": 22,
        "categoryId": 6,
        "subCategoryId": 6,
        "name": "American Baccarat Zero Commission",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/AmericanBaccaratZeroCommission.jpg"
      },
      {
        "id": 1860,
        "providerId": 22,
        "categoryId": 2,
        "subCategoryId": 2,
        "name": "Haunted House",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/HauntedHouse.jpg"
      },
      {
        "id": 1861,
        "providerId": 22,
        "categoryId": 2,
        "subCategoryId": 2,
        "name": "Indian Cash Catcher",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/IndianCashCatcher.jpg"
      },
      {
        "id": 1862,
        "providerId": 22,
        "categoryId": 2,
        "subCategoryId": 2,
        "name": "Jugglenaut",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/Jugglenaut.jpg"
      },
      {
        "id": 1863,
        "providerId": 22,
        "categoryId": 2,
        "subCategoryId": 2,
        "name": "Jungle Rumble",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/JungleRumble.jpg"
      },
      {
        "id": 1864,
        "providerId": 22,
        "categoryId": 2,
        "subCategoryId": 2,
        "name": "Kane's Inferno",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/KanesInferno.jpg"
      },
      {
        "id": 1865,
        "providerId": 22,
        "categoryId": 2,
        "subCategoryId": 2,
        "name": "King Tut's Tomb",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/KingTutsTomb.jpg"
      },
      {
        "id": 1866,
        "providerId": 22,
        "categoryId": 2,
        "subCategoryId": 2,
        "name": "Knockout Football",
        "providerName": "Habanero",
        "imageUrl": "https://cdn-relum.apidigi.com/Img/Games/Habanero/KnockoutFootball.jpg"
      }
    ]
  }
}
