import React from 'react';
import styles from './private.module.less';
import { PeriodicIcon } from '../../lib/Icons';
import { TYPE_PERIODIC } from '../../enums/tournament';

const Periodic = ({ type, state }) => (
    <>
      {type === TYPE_PERIODIC && (
        <div className={styles.typeStatus}>
          <PeriodicIcon />
          <span className={styles.statusName}>{state.translations?.periodic || "Periodic"}</span>
        </div>
      )}
    </>
  );

export default Periodic;