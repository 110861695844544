import { useMemo } from 'react';
import useGetBreakpoints from '../../../lib/hooks/useGetBreakpoints';

export const useGetGamesCount = () => {
  const { breakpoint } = useGetBreakpoints();

  return useMemo(() => {
    let gamesCount = 12;

    if (breakpoint === 'xl' || breakpoint === 'lg') {
      gamesCount = 10;
    } else if (breakpoint === 'md') {
      gamesCount = 8;
    }

    return gamesCount;
  }, []);
};