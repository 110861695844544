import React from 'react';
import { getPrizeType } from 'utils/helper';
import LeaderboardAnimation from './LeaderboardAnimation';

const getLeaderboardDataWhenNotPlayers = (leaderboardData, prizes, currency, xFreeRound) => {
  const data = JSON.parse(leaderboardData);
  if (data.length < prizes.length) {
    const count = prizes.length - data.length;
    for (let i = 0; i < count; i++) {
      let prize = '';
      if (prizes[data.length] !== undefined) {
        prize = getPrizeType(prizes[data.length], currency, xFreeRound);
      }
      data.push({
        id: Math.random(),
        userName: '-',
        score: '-',
        place: data.length + 1,
        prize,
      });
    }
  }
  return data;
};

export const LeaderboardTable = ({ state, leaderboardData, prizes, myScore, currency }) => {
  const leaderboard = getLeaderboardDataWhenNotPlayers(JSON.stringify(leaderboardData), prizes, currency, state.translations?.xFreeRound);

  return (
    <table>
      <thead>
      <tr>
        <th className='th_1'>#</th>
        <th className='th_2'>{state.translations?.player}</th>
        <th className='th_3'>{state.translations?.score}</th>
        <th className='th_4'>{state.translations?.toWin}</th>
      </tr>
      </thead>

      <LeaderboardAnimation
        leaderboardData={leaderboard}
        prizes={prizes}
        myScore={myScore}
        currency={currency}
      />
    </table>
  );
};