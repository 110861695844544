import React, { useContext } from 'react';
import styles from '../styles.module.less';
import { AuthContext } from '../../../context/AuthContext';
import { updateParentHeight } from '../../../services/processPostMessage/ProcessPostMessage';
import classNames from 'classnames';

export default (props) => {
  const { tournament } = props;
  const [state] = useContext(AuthContext);
  // updateParentHeight();

  return (
    <div className={styles.tournamentSingleRulesWrapper}>
      <div className={styles.tournamentSingleRulesTitle}>{state.translations?.tournamentRules}</div>
      <div className={classNames(styles.tournamentSingleRules, 'ql-editor')}
           dangerouslySetInnerHTML={{ __html: tournament?.rules ?? '' }} />
    </div>
  );
}
