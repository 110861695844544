import React, { useContext } from 'react';
import { Col } from 'antd';
import classNames from 'classnames';
import { FirstPlaceIcon, SecondPlaceIcon, thirdPlaceIcon, fourthPlaceIcon } from 'lib/Icons';
import { AuthContext } from 'context/AuthContext';
import { formatNumber, makeOrdinal } from 'utils/helper';
import styles from '../../styles.module.less';

const LeaderboardItem = (props) => {
  const { leaderboard, itemIndex } = props;
  const { isMyPlace, score, userName, place, prize } = leaderboard;

  const [state] = useContext(AuthContext);

  const placeList = {
    first : state?.translations?.["1stPlace"],
    second : state?.translations?.["2ndPlace"],
    third : state?.translations?.["3rdPlace"],
    fourth : state?.translations?.["4thPlace"]
  };

  const iconsList = {
    0: FirstPlaceIcon,
    1: SecondPlaceIcon,
    2: thirdPlaceIcon,
    3: fourthPlaceIcon,
  };

  const Icon = iconsList[itemIndex];
  return (
    <Col className={classNames('col-25 col-md-50 col-sm-100 leaderboardItemColl')}>
      <div className={classNames(styles.leaderboardItem)}>
        <div className={styles.leaderboardItemPlaceInfo}>
          <Icon />
          <div className={styles.leaderboardItemPlaceInfoTitle}>
            {isMyPlace ? state.translations?.myScore : `${makeOrdinal(place,placeList)}`}
          </div>
        </div>
        <div className={styles.leaderboardItemDetailInfo}>
          <div className={styles.leaderboardItemDetailInfoRow}>
            <div className={styles.leaderboardItemDetailInfoColumnFirst}>
              {isMyPlace ? state.translations?.place : state.translations?.player}
            </div>
            <div className={styles.leaderboardItemDetailInfoColumnSecond}>
              {isMyPlace ? makeOrdinal(place,placeList) : userName}
            </div>
          </div>

          <div className={styles.leaderboardItemDetailInfoRow}>
            <div className={styles.leaderboardItemDetailInfoColumnFirst}>
              {state.translations?.score}
            </div>
            <div className={styles.leaderboardItemDetailInfoColumnSecond}>
              {formatNumber(score)}
            </div>
          </div>

          <div className={styles.leaderboardItemDetailInfoRow}>
            <div className={styles.leaderboardItemDetailInfoColumnFirst}>
              {state.translations?.toWin}
            </div>
            <div className={styles.leaderboardItemDetailInfoColumnSecond}>
              {prize}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default LeaderboardItem;